import React, { useState, useEffect } from 'react'

import { useParams, Link } from 'react-router-dom'
import NavContainer from '../../containers/navContainer/NavContainer'

import Curriculum from '../../components/curriculum/Curriculum'
import WhyCM from '../../components/whyCM/WhyCM'
import FAQs from '../../components/FAQs/FAQs'
import Footer from '../../components/footer/Footer'

import '../../../css/pages/coursePage/coursePage.css'

import { courseData } from '../../data/courseData'

import { FaLinkedinIn } from 'react-icons/fa'
import { BsInstagram } from 'react-icons/bs'



const CoursePage = () => {

  const [currentCourse, setCurrentCourse] = useState();
  const { courseName } = useParams();

  useEffect(() => {
    const course = courseData.find(({ courses }) => {
      courses.find((course) => {
        if (course.slug === courseName) {
          setCurrentCourse(course.courseDetail);
        }
      })
    });
  }, [courseName])
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div >
      <NavContainer />
      <div className='coursepage-outer-container'>
        <div className="components-outer-container">
          <div className="coursepage-container">
            <div className="top">
              <div className="left" data-aos="fade-right">
                <div className="left-top">
                  <div className="title-image">
                    {console.log(currentCourse)}
                    <img src={currentCourse?.courseImage} alt="" />
                  </div>
                  <h1>{currentCourse?.title}</h1>
                </div>
                <p className="course-descriptionTop">{currentCourse?.descriptionTop}</p>
                <div className="left-bottom">
                  <div className='left-bottom-first'>
                    <div className="price-div">
                      <span className='price-span'>{currentCourse?.priceInfo?.current}</span>
                      <span className='price-span2'>{currentCourse?.priceInfo.original}</span>
                    </div>
                    <div>
                      <button ><Link to="/enrollnow" state={{courseName:currentCourse?.title}}className='enrollnow-button'>Enroll Now</Link></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom" >
              <div className="bottom-left">
                <div className="bottom-left-top" data-aos="fade-up">
                  <h2>What you'll learn</h2>
                  <ul>
                    {currentCourse?.learningOutcomes.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
                <div className="bottom-left-bottom" data-aos="fade-up">
                  <h2>Course Overview</h2>
                  <div className="first">
                    {currentCourse?.courseDescription.map((item, index) => (
                      <p>{item}</p>
                    ))}
                  </div>

                </div>
                {currentCourse?.isProject === true?
                
                  <div className="bottom-left-bottom" data-aos="fade-up">
                    
                    <h2>Real-time projects</h2>
                    <div className="first">
                      <WhyCM />
                    </div>
                  </div>
                  :<></>
                }

              </div>
              <div className="bottom-right" data-aos="fade-up">
                <h2>Meet the Instructor</h2>
                {currentCourse?.instructor.map((item, index) => (
                  <div className="instructor">
                    <div className="image-container">
                      <img src={item.image} alt="team member" />
                    </div>
                    <div className="data-container">
                      <div className="description">
                        <p>{item.description1}</p>
                        <p>{item.description2}</p>
                      </div>
                      <div className="social-icons">
                        <a href={item.linkedin} target="_blank" rel="noreferrer">
                          <FaLinkedinIn />
                        </a>
                        <a href={item.instagram} target="_blank" rel="noreferrer">
                          <BsInstagram />
                        </a>
                        
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Curriculum courseCurriculum = {currentCourse?.courseCurriculum}/>

      <FAQs />
      <Footer />
    </div>
  )
}

export default CoursePage