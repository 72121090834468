import React from 'react'
import '../../../css/components/header/header.css'
import headerImage from '../../assets/Programming-icon.png'

const Header = () => {
  
  return (
    <div className="normal-outer-container">
      <div className="components-outer-container">
        <div className="header-container">
          <div className="left" data-aos="fade-right">
            <div className="first">
              <h1>Become Job Ready</h1> 
              <h1 className="cm-gradient-text">with Latest Technology</h1>
            </div>
            <div className="second">
              
              <p>Courses that create careers through immersive experiences & mentorship from industry leaders.</p>
            </div>
            <div className="third">
              <button className="cm-gradient-button"><a href="#courses">Explore Courses</a></button>
            </div>
          </div>
          <div className="right" data-aos="fade-left">
            <div className="header-image">
              <img src={headerImage} alt="header" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
