import sumit from "../assets/team-photo/sumit.png"
import naresh from "../assets/team-photo/naresh.png"
import adobe from "../assets/adobe_logo.png"
import paytm from "../assets/Paytm-Logo.svg"
import samsung from "../assets/Samsung-Logo.png"


export const teamData = [
    {
        id: 1,
        image: sumit,
        description1: 'Sumit is a Lead Data Scientist and an avid teacher, currently working at Paytm. He has a unique way for explaning complex things in super simple manner.',
        description2: 'He has over 5+ years of teaching experience and has trained over 10,000 students in classroom & online courses in the past.',
        linkedin: 'https://www.linkedin.com/in/tyagi-py/',
        instagram: 'https://www.instagram.com/tyagi.py/',
        youtube: '',
        comp1: adobe,
        comp2: samsung,

    },
    {
        id: 2,
        image: naresh,
        description1: 'Naresh is a Software Engineer and a programming instructor, currently working at Samsung. He has trained over 8000+ students in Competitive Programming and Data Structure & Algorithms over the last 5+ years of his teaching experience.',
        description2: 'He has a unique way for explaning complex things in super simple manner.',
        linkedin: 'https://www.linkedin.com/in/naresh151saharan/',
        instagram: 'https://www.instagram.com/naresh_saharan151/',
        comp1: paytm,
    },
    {
        id: 3,
        image: "Mandeep Attri",
        description1: "Mandeep is a Software Engineer and a programming instructor, currently working at Samsung. He loves to solve challenging problems and teach students.",
        description2: "He has won many hackathons. In the past 2 years, he has mentored thousands in MERN Stack are his favorite topics.",
        linkedin: 'https://www.linkedin.com/in/mandeep-attri/',
        instagram: 'https://www.instagram.com/attrimandeep/',
    },
    {
        id: 4,
        image: "Sagar Jangra",
        description1: 'Naresh is a Software Engineer and a programming instructor, currently working at Samsung. He has trained over 8000+ students in Competitive Programming and Data Structure & Algorithms over the last 5+ years of his teaching experience.',
        description2: 'He has a unique way for explaning complex things in super simple manner.',
        linkedin: 'https://www.linkedin.com/in/sagarjangra/',
        instagram: 'https://www.instagram.com/_sagarjangra/',
    },
]