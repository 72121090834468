import React, { useState } from 'react'
import { Link } from 'react-router-dom'

//import './exploreCourses.css'
import '../../../css/components/exploreCourses/exploreCourses.css'
import { courseData } from '../../data/courseData'

const ExploreCourses = () => {

  const [courses, setCourses] = useState(courseData);

  return (
    <div className="explore-path-outer-container">
      <div className="components-outer-container">
        <div className='explore-courses-container' id="courses">
          <div className="top" data-aos="fade-up">
            <div className="first" >
              <h1>Select Your Course</h1>
            </div>
          </div>
          <div className="bottom">
            {
              courses.map((courseGroup) => {
                return (
                  <div className="course-container" key={courseGroup.title}>
                    <h2>{courseGroup.title}</h2>
                    <div className="course-internal-container">
                      {courseGroup.courses.map((course, index) => {
                        const { courseImage, title, mentors, slug } = course;
                        return (
                          <div className="course" key={index}  data-aos="zoom-in">
                            <div className="course-top">
                              <div className='course-icon'>
                                <img src={courseImage} alt="icon" />
                              </div>
                              <div className='course-heading'>
                                <p className="course-title">{title}</p>
                              </div>
                            </div>
                        
                            <div className="course-info">
                              <div className="first">
                              </div>
                              <div className="second">
                                <p className='course-mentors'>{mentors}</p>
                                {<button className="cm-gradient course-explore-btn"><Link to={`/courses/${slug}`}>Explore Now</Link></button>}
                              </div>

                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExploreCourses