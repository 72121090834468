import React from 'react'
import { FaLinkedinIn } from 'react-icons/fa'
import { BsInstagram } from 'react-icons/bs'

import '../../../css/components/footer/footer.css'
import { navData } from '../navbar/Navbar'
import logo from '../../assets/logonew.png'

const Footer = () => {

  const linkedin = ""
  const instagram = ""

  return (
    <div className="super-container">
      <div className="components-outer-container">
        <div className="footer-container">
          <div className="first">
            <div className="top">
              <img src={logo} alt="" />
            </div>
            <div className="bottom">
              <p>Sprint Code is dedicated to providing high-quality education through live classes, ensuring an engaging and interactive learning experience. We offer our courses at affordable prices, giving students lifetime access to top-notch content and resources.</p>
              <p>©2024 Sprint Code. All rights reserved</p>
            </div>
          </div>
          <div className="second">
            <ul>
              {navData.map((item) => (
                <li key={item.id}>
                  <a href={item.link} className="footer-nav">{item.name}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="third">
            <div className="top">
              <p>Get in Touch</p>
              <div className="social-icons">
                <a href={linkedin} target="_blank" rel="noreferrer">
                  <FaLinkedinIn />
                </a>
                <a href={instagram} target="_blank" rel="noreferrer">
                  <BsInstagram />
                </a>
              </div>
            </div>
            <div className="bottom">
              <p>Contact Us</p>
              <a href="tel:+91999999999"> +91 999999999 </a>
              <a href="mailto:hello@sprintcode.com">hello@sprintcode.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer