import React from 'react'

import NavContainer from '../../containers/navContainer/NavContainer'
import Header from '../../components/header/Header'
import ExploreCourses from '../../components/exploreCourses/ExploreCourses'
import MeetTeam from '../../components/meetTeam/MeetTeam'
import FAQs from '../../components/FAQs/FAQs'
import Footer from '../../components/footer/Footer'
import WhatsApp from '../../components/whatsApp/WhatsApp'

const HomePage = () => {
  return (
    <div>
      <NavContainer />
      <Header />
      <ExploreCourses />
      <WhatsApp />
      <MeetTeam />
      <FAQs />
      <Footer />
    </div>
  )
}

export default HomePage